import React, { useEffect, useState } from "react";
import Modal from "./Components/Modal";
import AnnouncementForm from "./Announcements/AnnouncementForm";
import PageModal from "./Components/PageModal";
import {
  getAllDocsSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import { deleteDocById } from "../../utils/firebase/Firebase.utils";

function Announcements() {
  const links = ["All", "Announcements", "Newsletters"];
  const [activeLink, setActiveLink] = React.useState("All");
  const [addMode, setAddMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      await getAllDocsSnap("announcements", (data) => {
        data.sort = (a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        };

        setAnnouncementArray(data);
        setLoading(false);
      });
    };
    fetchAnnouncements();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const activeLinkStyle = "bg-green-500 text-black p-2";

  // Dummy announcements data

  const currentItems =
    activeLink === "All"
      ? announcementArray
      : announcementArray.filter(
          (announcement) => announcement.type === activeLink.toLowerCase()
        );

  const filteredAnnouncements = currentItems.filter((announcement) =>
    announcement.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreate = () => {
    setAddMode(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleStatus = async (announcement) => {
    const newStatus =
      announcement.status === "published" ? "unpublished" : "published";

    await setDocData("announcements", announcement.id, { status: newStatus });
    toast.success("Status updated successfully");
  };

  const handleDelete = async (announcement) => {
    if (window.confirm("Are you sure you want to delete this announcement?")) {
      await deleteDocById("announcements", announcement.id);
      toast.success("Announcement deleted successfully");
    }
  };

  const handleViewMode = (announcement) => {
    setSelectedAnnouncement(announcement);
    setViewMode(true);
  };

  return (
    <div className="text-blue-900 p-4 text-xs">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <h1 className="font-bold text-xs lg:text-sm">
          Announcements and Advisory
        </h1>
        <div className="flex items-center gap-4">
          <button className="blue-button" onClick={handleCreate}>
            Create
          </button>
          <button
            onClick={() => setEditMode(!editMode)}
            className="blue-button"
          >
            {editMode ? "Exit Edit Mode" : "Edit"}
          </button>
        </div>
      </div>

      {/* Filter Section */}
      <div className="flex items-center lg:justify-between border-2 border-gray-300 p-4 rounded-lg flex-wrap justify-center text-sm gap-2">
        <div className="links-container ">
          {links.map((link, index) => (
            <div
              key={index}
              className={
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              }
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="Search announcements"
          className="border-2 border-gray-300 px-4 py-2 w-96 rounded-lg"
          onChange={handleSearch}
        />
        {/* <button className="hidden lg:flex ml-2">Filter</button> */}
      </div>

      {/* Announcements Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
        {announcementArray.length === 0 ? (
          <div className="text-gray-600 col-span-full p-8">
            No announcements to show.
          </div>
        ) : (
          filteredAnnouncements.map((announcement, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden relative py-8 px-4"
            >
              <img
                src={announcement.poster}
                alt={announcement.title}
                className="w-full h-48 object-contain object-center"
              />
              <div className="p-4 space-y-2">
                <h3 className="font-bold text-xl text-blue-900">
                  {announcement.title}
                </h3>
                <p className="text-gray-600">{announcement.description}</p>
              </div>
              <div
                className={`capitalize absolute top-1 right-2 ${announcement.status}`}
                onClick={() => handleToggleStatus(announcement)}
              >
                {announcement.status}
              </div>

              <div
                onClick={() => handleViewMode(announcement)}
                className="underline cursor-pointer"
              >
                View Details
              </div>

              <div>
                {editMode && (
                  <button
                    className="underline"
                    onClick={() => handleDelete(announcement)}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {addMode && (
        <PageModal>
          <AnnouncementForm setAddMode={setAddMode} />
        </PageModal>
      )}

      {viewMode && (
        <PageModal>
          <AnnouncementForm
            setAddMode={setViewMode}
            data={selectedAnnouncement}
          />
        </PageModal>
      )}
    </div>
  );
}

export default Announcements;

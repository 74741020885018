import React, { useEffect } from "react";
import PageModal from "./Components/PageModal";
import BillingPageDetails from "./Billings/BillingPageDetails";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import BentoTable from "../../components/BentoTable";
import BillingCard from "./Billings/BillingCard";

function Billing() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [ViewMode, setViewMode] = React.useState(false);
  const [selectedBilling, setSelectedBilling] = React.useState(null);
  const [bills, setBills] = React.useState([]);
  const [loading, setIsLoading] = React.useState(true);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);

  useEffect(() => {
    const fetchBillings = async () => {
      await getAllDocsSnap("billings", (data) => {
        // sort billings
        data.sort(
          (a, b) => b.soaNumber.split("-")[1] - a.soaNumber.split("-")[1]
        );
        setBills(data);
        setIsLoading(false);
      });
    };
    fetchBillings();
  }, []);

  const billings = bills.map((bill) => {
    return {
      id: bill.id,
      soaNo: bill.soaNumber,
      name: bill.name,
      unitNo: bill.identifier,
      billingDate: bill.dueDate,
      status: bill.status,
      amount: bill.amount,
      paymentDate: bill.dueDate,
      depositSlip: bill.depositSlip,
    };
  });

  const tableHeaders = [
    "SOA No.",
    "Lot No.",
    "Name",
    "Billing Date",
    "Status",
    "Amount",
    "Payment Date",
    "Actions",
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBillings = billings.filter(
    (billing) =>
      billing.soaNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      billing.unitNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      billing.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleView = (billing) => {
    setSelectedBilling(billing);
    setViewMode(true);
  };

  const slicedItems = filteredBillings.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div className="text-gray-600 p-2 space-y-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-xl">Billing</div>
        <div>
          Dues billing automatically computes based on rate and area in sqms of
          lots on an annual basis
        </div>
        <div className="flex items-center justify-normal gap-4">
          {/* <button className="blue-button">
            Create Billing
          </button>
          <button className="blue-button">
            Download PDF Report
          </button> */}
        </div>
      </div>
      <div className="border border-gray-300 p-2 rounded-lg flex items-center justify-between ">
        <input
          type="search"
          placeholder="Search by Billing No. or Name"
          className="rounded-lg border border-gray-300 px-4 py-2 min-w-96"
          onChange={handleSearch}
        />
        {/* <button>Filter</button> */}
      </div>

      <BentoTable
        data={filteredBillings}
        label={"All Billings"}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="divide-y divide-gray-200">
          {slicedItems.map((billing, index) => (
            <div
              className="odd:bg-slate-100 hover:bg-gray-300 cursor-pointer"
              key={index}
              onClick={() => handleView(billing)}
            >
              <BillingCard data={billing} />
            </div>
          ))}
        </div>
      </BentoTable>

      {ViewMode && (
        <PageModal>
          <BillingPageDetails
            data={selectedBilling}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default Billing;

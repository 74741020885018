import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useSelector } from "react-redux";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import BillingCard from "./Billing/BillingCard";
import { Link } from "react-router-dom";

function DuesUser() {
  const currentUser = useSelector((state) => state.userObject);

  const [duesBills, setDuesBills] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchBills = async () => {
      await getAllDocsSnap("billings", (data) => {
        const dues = data.filter(
          (bill) =>
            currentUser.linkedIdentifiers.includes(bill.identifier) &&
            bill.billingType === "Dues"
        );
        setDuesBills(dues);
        setLoading(false);
      });
    };
    fetchBills();
  }, []);

  if (loading) {
    return (
      <UserScreenWindow label="Dues">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  return (
    <UserScreenWindow label="Dues">
      <div className="text-center font-bold text-blue-900 mt-4">
        Association Dues
      </div>

      {duesBills.map((billing, index) => (
        <Link to={`/billing-details/${billing.id}`} key={index}>
          <BillingCard billing={billing} />
        </Link>
      ))}
    </UserScreenWindow>
  );
}

export default DuesUser;

import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import PageModal from "../Dashboard/Components/PageModal";
import CarStickerApplicationDetails from "./CarStickerApplicationDetails";

export default function CarStickerApplications() {
  const [applications, setApplications] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedApplication, setSelectedApplication] = React.useState(null);
  const [viewMode, setViewMode] = React.useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      await getAllDocsSnap("carStickers", (data) => {
        setApplications(data);
        setLoading(false);
      });
    };
    fetchApplications();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleViewMode = (application) => {
    setSelectedApplication(application);
    setViewMode(true);
  };

  return (
    <div className="text-left">
      <div className="header mt-4">Car Sticker Applications</div>

      {applications.map((application, index) => (
        <div
          className="card text-xs text-gray-600 cursor-pointer hover:bg-gray-300 flex items-center gap-4"
          onClick={() => handleViewMode(application)}
          key={index}
        >
          <div>{application.identifier}</div>
          <div>{application.requestedBy}</div>
          <div>{application.plateNumber}</div>
          <div>{application.requestedAt}</div>
        </div>
      ))}

      <div className="mt-4"></div>

      {viewMode && (
        <PageModal>
          <CarStickerApplicationDetails
            data={selectedApplication}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

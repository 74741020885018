import React from "react";
import InputBox from "../Dashboard/Components/InputBox";
import SelectInput from "../Dashboard/Components/SelectInput";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addNewDoc } from "../../utils/firebase/Firebase.utils";

export default function TodosForm({ setAddTodo }) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState({});


  const priorityOptions = [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveTodo = async (e) => {
    e.preventDefault();

    const docObject = {
      ...formData,
      createdBy: currentUser.email,
      user: currentUser.uid,
      status: "pending",
    };
    await addNewDoc("todos", docObject);
    toast.success("Todo added successfully");
    setAddTodo(false);
  };

  return (
    <div className="small-form relative pt-8 text-sm">
      <button className="close-button" onClick={() => setAddTodo(false)}>
        Close
      </button>
      <div className="header">Add new Todo</div>
      <form onSubmit={handleSaveTodo}>
        <div>
          <InputBox
            label="Task Name"
            name="name"
            type="text"
            placeholder="Enter Todo"
            required
            onChange={handleChange}
          />
          <InputBox
            label="Description"
            name="description"
            type="text"
            placeholder="Enter description"
            onChange={handleChange}
          />

          <InputBox
            label="Due Date"
            name="dueDate"
            type="date"
            placeholder="Enter Due Date"
            required
            onChange={handleChange}
          />
          <div className="text-left">
            <SelectInput
              name="priority"
              label="Priority"
              options={priorityOptions}
              required
              onChange={(selected) =>
                setFormData({ ...formData, priority: selected.value })
              }
            />
          </div>
          <div className="mt-4">
            <button className="small-form-button" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

import React, { useState } from "react";
import InputBox from "../Dashboard/Components/InputBox";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addNewDoc, setDocData } from "../../utils/firebase/Firebase.utils";
import Label from "../Dashboard/Components/Label";
import SelectInput from "../Dashboard/Components/SelectInput";

export default function AddContactForm({
  data,
  setAddMode,
  setEditMode,
  editMode,
}) {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState(editMode ? data : {});
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (editMode) {
      const docObject = {
        ...formData,
        updatedBy: currentUser.email,
        updatedAt: new Date().toLocaleString(),
      };
      await setDocData("contacts", data.id, docObject);
      toast.success("Contact Updated Successfully");
      setEditMode(false);
      setSubmitting(false);
      return;
    }
    const docObject = {
      ...formData,
      createdBy: currentUser.email,
      user: currentUser.uid,
      createdAt: new Date().toLocaleString(),
    };

    await addNewDoc("contacts", docObject);
    toast.success("Contact Added Successfully");
    setAddMode(false);
    setSubmitting(false);
  };

  const categoryOptions = [
    {
      label: "HOA Directory",
      value: "HOA Directory",
    },
    {
      label: "Emergency Hotlines",
      value: "Emergency Hotlines",
    },
  ];

  return (
    <div className="small-form relative pt-8">
      <button
        className="close-button"
        onClick={() => {
          setAddMode(false);
          setEditMode(false);
        }}
      >
        Close
      </button>
      <div className="header">
        {editMode ? "Edit Contact" : "Add New Contact"}
      </div>

      <div>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div>
            <Label text={"Category"} />
            <SelectInput
              name="category"
              label={"Category"}
              value={categoryOptions.find(
                (option) => option.value === formData.category
              )}
              options={categoryOptions}
              onChange={(value) => {
                setFormData({ ...formData, category: value.value });
              }}
              required
            />
          </div>
          <InputBox
            name="name"
            label={"Name"}
            type="text"
            onChange={handleChange}
            placeholder={"Enter name of Contact"}
            value={formData.name}
            disabled={submitting}
            required
          />

          <InputBox
            name="title"
            label={"Title / Position"}
            type="text"
            onChange={handleChange}
            placeholder={"Enter Title"}
            required
            disabled={submitting}
            value={formData.title}
          />

          <InputBox
            name="department"
            label={"Department"}
            type="text"
            onChange={handleChange}
            placeholder={"Enter Title"}
            required
            disabled={submitting}
            value={formData.department}
          />

          <InputBox
            name="contactNumber"
            label={"Contact Number"}
            type="text"
            onChange={handleChange}
            placeholder={"Enter Contact Number"}
            required
            disabled={submitting}
            value={formData.contactNumber}
          />

          <InputBox
            name="landline"
            label={"Landline Number"}
            type="text"
            onChange={handleChange}
            disabled={submitting}
            placeholder={"Enter Landline Number"}
            value={formData.landline}
          />

          <InputBox
            name="email"
            label={"Email Address"}
            type="email"
            onChange={handleChange}
            disabled={submitting}
            placeholder={"Enter Email Address"}
            value={formData.email}
          />

          <div className="">
            <button
              className="mt-8 small-form-button"
              type={"submit"}
              disabled={submitting}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

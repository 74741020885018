import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import GuestPassCard from "./GuestPassCard";
import PageModal from "../Dashboard/Components/PageModal";
import GuestPassDetailsPage from "./GuestPassDetailsPage";

export default function GuestPass() {
  const [passes, setPasses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const links = ["all", "pending", "admitted", "completed", "returned"];
  const [activeLink, setActiveLink] = React.useState("pending");

  useEffect(() => {
    const fetchAllPasses = async () => {
      await getAllDocsSnap("guests", (data) => {

        //sort by request date
        data.sort((a, b) => {
          return new Date(b.requestDate) - new Date(a.requestDate);
        });
        setPasses(data);
        setLoading(false);
      });
    };
    fetchAllPasses();
  }, []);

  if (loading) {
    return <div>Loading..</div>;
  }

  const handleViewMode = (pass) => {
    setViewMode(true);
    setSelectedData(pass);
  };

  const currentPasses = passes.filter((pass) => {
    if (activeLink === "all") {
      return pass;
    }
    return pass.status.toLowerCase() === activeLink;
  });

  return (
    <div className="mt-4">
      <div className="header">Guest Pass Requests</div>

      <div className="flex items-center gap-2">
        {links.map((link, index) => (
          <div
            key={index}
            className={`capitalize ${
              activeLink === link ? "active-pill" : "inactive-pill"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      <div className="space-y-2 mt-4">
        {currentPasses.map((pass, index) => (
          <div key={index} onClick={() => handleViewMode(pass)}>
            <GuestPassCard data={pass} />
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <GuestPassDetailsPage data={selectedData} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}

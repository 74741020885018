import React from "react";
import SelectInput from "../Dashboard/Components/SelectInput";
import { useSelector } from "react-redux";
import Label from "../Dashboard/Components/Label";
import InputBox from "../Dashboard/Components/InputBox";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { setDocData } from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";

export default function CarStickerApplication({ setAddMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [currentUpload, setCurrentUpload] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [assignedId, setAssignedId] = React.useState(v4());

  const identifierOptions = currentUser.linkedIdentifiers.map((identifier) => {
    return {
      value: identifier,
      label: identifier,
    };
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (selected) => {
    setFormData({ ...formData, identifier: selected.value });
  };

  const handleUpload = async (e, type) => {
    //check if file is uploaded
    if (!e.target.files[0]) return;
    setCurrentUpload(type);

    const file = e.target.files[0];
    const storageRef = ref(
      storage,
      `carStickers/${assignedId}/${type}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, [type]: downloadURL }));
          setProgress(0);
        });
      }
    );
  };

  const handleSubmit = async (e) => {
    setIsProcessing(true);
    e.preventDefault();
    const docObject = {
      ...formData,
      status: "Pending",
      id: assignedId,
      userId: currentUser.uid,
      requestedAt: new Date().toLocaleString(),
      requestedBy: currentUser.email,
    };

    await setDocData("carStickers", assignedId, docObject);
    toast.success("Application submitted successfully");

    setAddMode(false);
    setIsProcessing(false);
  };

  return (
    <div className="page relative text-sm text-gray-600">
      <div className="header">Car Sticker Application</div>
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="mt-4">
        <form action="" onSubmit={handleSubmit}>
          <div className="space-y-2">
            <Label label="Select Lot Number" />
            <SelectInput
              options={identifierOptions}
              onChange={(selected) => handleSelect(selected)}
              value={identifierOptions.find(
                (option) => option.value === formData.identifier
              )}
            />
            <InputBox
              label="Plate Number"
              placeholder={"Plate Number"}
              name="plateNumber"
              onChange={handleChange}
              value={formData.plateNumber}
            />
            <InputBox
              label="Car Model"
              placeholder={"e.g. Mazda 3"}
              name="carModel"
              onChange={handleChange}
              value={formData.carModel}
            />
            <InputBox
              label="Car Color"
              placeholder={"e.g. Red"}
              name="carColor"
              onChange={handleChange}
              value={formData.carColor}
            />
            <InputBox
              label="Car Owner"
              placeholder={"e.g. John Doe"}
              name="carOwner"
              onChange={handleChange}
              value={formData.carOwner}
            />

            <Label label="Upload OR/CR" />
            <input type="file" onChange={(e) => handleUpload(e, "ORCR")} />
            {currentUpload === "ORCR" && progress > 0 && (
              <div className="w-full">
                <progress value={progress} max="100" />
              </div>
            )}

            <Label label="Upload Photo of the Car" />
            <input type="file" onChange={(e) => handleUpload(e, "Photo")} />
            {currentUpload === "Photo" && progress > 0 && (
              <div className="w-full">
                <progress value={progress} max="100" />
              </div>
            )}
          </div>

          <div>
            <button
              className="blue-button w-full mt-4"
              type="submit"
              disabled={isProcessing}
            >
              Submit Application
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

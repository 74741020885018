import React from "react";
import {
  deleteDocById,
  getAllDocsSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Modal from "../Components/Modal";
import TodosForm from "../../Todos/TodosForm";

export default function Todos() {
  const currentUser = useSelector((state) => state.userObject);
  const [todos, setTodos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activeFilter, setActiveFilter] = React.useState("pending");
  const [addMode, setAddMode] = React.useState(false);

  const filters = ["all", "pending", "completed"];

  React.useEffect(() => {
    const fetchTodos = async () => {
      await getAllDocsSnap("todos", (data) => {
        const filteredData = data.filter(
          (todo) => todo.user === currentUser.uid && todo.status !== "deleted"
        );

        filteredData.sort((a, b) => {
          return a.priority === "High" ? -1 : 1;
        });

        setTodos(data);
        setLoading(false);
      });
    };
    fetchTodos();
  }, []);

  const currentItems = todos.filter((todo) => {
    if (activeFilter === "all") return true;
    return todo.status === activeFilter;
  });

  const handleAdd = () => {
    setAddMode(!addMode);
  };

  return (
    <div>
      {addMode && (
        <Modal>
          <TodosForm setAddTodo={setAddMode} />
        </Modal>
      )}

      <div className="text-left text-sm text-gray-600">
        <div className="header">Task Management</div>
        <div>{currentUser.displayName}</div>
      </div>

      <div className="mt-4 text-left">
        <button className="blue-button" onClick={handleAdd}>
          Add To do
        </button>
      </div>

      <div className="mt-4 flex items-center gap-4">
        {filters.map((filter, index) => (
          <div
            key={index}
            className={`capitalize ${
              activeFilter === filter ? "active-pill" : "inactive-pill"
            }`}
            onClick={() => setActiveFilter(filter)}
          >
            {filter}
          </div>
        ))}
      </div>

      <div className="mt-4">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {currentItems.map((todo, index) => (
              <div key={index} className="todo">
                <TodoCard todo={todo} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const TodoCard = ({ todo }) => {
  const toggleStatus = async (id, status) => {
    const newStatus = status === "completed" ? "pending" : "completed";
    await setDocData("todos", id, { status: newStatus });
    toast.success("Status updated successfully");
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this todo?")) {
      await deleteDocById("todos", id);
      toast.success("Todo deleted successfully");
    }
  };

  return (
    <div className="border border-gray-200 rounded-lg p-2 text-xs text-gray-600 mt-2 text-left relative bg-white">
      <div className="capitalize font-bold text-blue-900">{todo.name}</div>
      <div className="todo-description">{todo.description}</div>
      <div
        className={`capitalize absolute top-1 right-2 ${todo.status}`}
        onClick={() => toggleStatus(todo.id, todo.status)}
      >
        {todo.status}
      </div>
      <div className={`capitalize ${todo.priority}`}>{todo.priority}</div>

      <div className="mt-8">
        <button className="underline" onClick={() => handleDelete(todo.id)}>
          Delete
        </button>
      </div>
    </div>
  );
};

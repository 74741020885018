import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import AllResidentsTable from "./AllResidentsTable";
import Modal from "../Components/Modal";
import ResidentsDetails from "./ResidentsDetails";
import AddResidentForm from "../Forms/AddResidentForm";
import ResidentDetails from "./ResidentDetails";
import ResidentsCard from "./ResidentsCard";
import { Link } from "react-router-dom";
import BentoContainer from "../../../components/BentoContainer";
import BentoTable from "../../../components/BentoTable";

function AllResidents({ searchTerm }) {
  const [residentSummary, setResidentSummary] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedResident, setSelectedResident] = React.useState({});
  const [activeLink, setActiveLink] = React.useState("Resident Information");
  const [residents, setResidents] = React.useState([]);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        //arrange by owner1
        residents.sort((a, b) => {
          if (a.owner1 < b.owner1) {
            return -1;
          }
          if (a.owner1 > b.owner1) {
            return 1;
          }
          return 0;
        });

        setResidents(residents);
        setIsLoading(false);
      });
    };

    fetchResidents();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const tableHeaders = [
    "Lot Owner",
    "Phase Block and Lot Number",
    "Lot Size",
    "Occupants",
    "Email Address",
    "Phone Number",
    "Tenanted",
    "More Information",
  ];

  const handleViewDetails = () => {
    setViewMode(true);
  };

  const handleViewItem = (resident) => {
    setSelectedResident(resident.id);
    setViewMode(true);
  };

  const filteredResidents = residents.filter(
    (resident) =>
      resident.owner1?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.owner2?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.identifier
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      resident.address
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      resident.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.block?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.phase?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.lot?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedItems = filteredResidents.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div>
      <BentoTable
        label="All Lot Owners"
        data={filteredResidents}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="text-xs">
          <div>
            <div className=" ">
              <div className=" items-center justify-normal gap-4 font-bold bg-white p-4 text-blue-900 hidden lg:flex flex-wrap">
                <div className="w-32 text-left">Status</div>
                <div className="w-48 text-left">Lot Owner</div>
                <div className="w-36 text-left">Nationality</div>
                <div className="w-48 text-left">Lot Number</div>
                <div className="w-48 text-left">Email</div>
                <div className="w-32 text-left">Lot Size</div>
                <div className="w-48 text-left">No. of Occupants</div>
              </div>
              {slicedItems.map((resident, index) => (
                <div
                  className="border-t border-gray-300 odd:bg-slate-200 even:bg-slate-100"
                  key={index}
                >
                  <Link to={`/resident/${resident.id}`}>
                    <ResidentsCard resident={resident} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {/* modal to view the selected lot owner */}
          {viewMode && (
            <Modal>
              <div className="relative lg:w-[900px] p-4 ">
                <div
                  className="absolute top-0 right-4 underline cursor-pointer text-xs"
                  onClick={() => setViewMode(false)}
                >
                  Close
                </div>
                <div className="lg:w-[800px] min-h-[500px] w-[95%]">
                  <ResidentDetails
                    selectedResident={selectedResident}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                  />
                </div>
              </div>
            </Modal>
          )}
        </div>
      </BentoTable>
    </div>
  );
}

export default AllResidents;

import React, { useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileSettings from "../Settings/ProfileSettings";
import { IoSettingsOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";

import UserAccess from "../Settings/UserManagement";
import GeneralSetup from "../Settings/GeneralSetup";

function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeParams = searchParams.get("link");
  const [activeLink, setActiveLink] = React.useState(activeParams || "setup");
  const [notificationCount, setNotificationCount] = React.useState(0);

  useEffect(() => {
    setActiveLink(activeParams);
  }, [activeParams]);

  const settingsMenuItems = [
    {
      name: "General Setup",
      icon: <IoSettingsOutline />,
      link: "setup",
    },
    {
      name: "Profile",
      icon: <AccountCircleIcon />,
      link: "profile",
    },
    {
      name: "User Management",
      icon: <AccountCircleIcon />,
      link: "usermanagement",
    },
  ];

  return (
    <div>
      <div className="font-bold text-blue-900 text-xl text-left">Settings</div>
      <div className="flex items-start justify-normal gap-4">
        <div className="flex flex-col gap-2 border text-xs w-60 p-2">
          {settingsMenuItems.map((item, index) => (
            <div
              key={index}
              className={` cursor-pointer text-left ${
                activeLink === item.link
                  ? "text-blue-900 font-bold "
                  : " text-gray-600"
              }`}
              onClick={() => setActiveLink(item.link)}
            >
              <div>{item.name}</div>
              {/* <div>{notificationCount}</div> */}
            </div>
          ))}
        </div>
        <div className="">
          {activeLink === "setup" && <GeneralSetup />}
          {activeLink === "profile" && <ProfileSettings />}
          {activeLink === "usermanagement" && (
            <UserAccess
              notificationCount={notificationCount}
              setNotificationCount={setNotificationCount}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;

import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useParams } from "react-router-dom";
import { getDocData, setDocData } from "../../utils/firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InputBox from "../Dashboard/Components/InputBox";
import { storage } from "../../utils/firebase/Firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function BillingDetails() {
  const [billDetails, setBillDetails] = React.useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [remarks, setRemarks] = React.useState("");
  const [depositSlip, setDepositSlip] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const fetchBillDetails = async () => {
      const data = await getDocData("billings", id);
      setBillDetails(data);
      setIsLoading(false);
    };
    fetchBillDetails();
  }, []);

  //firebasedate converter
  const date = new Date(
    billDetails?.dueDate?.seconds * 1000
  ).toLocaleDateString();

  if (isLoading) {
    return (
      <UserScreenWindow label="Billing Details">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `depositSlips/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDepositSlip(downloadURL);
          toast.success("File uploaded successfully");
        });
      }
    );
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRemarks(value);
  };

  const handleSubmit = async () => {
    if (!depositSlip) {
      toast.error("Please upload a deposit slip");
      return;
    }

    if (!remarks) {
      toast.error("Please enter your remarks");
      return;
    }

    setIsProcessing(true);
    const data = {
      status: "deposit slip uploaded",
      depositSlip: depositSlip,
      remarks: remarks,
    };
    await setDocData("billings", id, data);
    setIsProcessing(false);
    toast.success("Payment Uploaded!");
    navigate(`/payment-confirmed/${id}`);
  };

  return (
    <UserScreenWindow label="Billing Details">
      <div>
        <div className="font-bold text-blue-900 text-center text-sm mt-8 mb-4">
          Billing Details
        </div>

        <div className="p-2">
          <div>Billing For: {billDetails.identifier}</div>
          <div>Bill Type: {billDetails.billingType}</div>
          <div>Due Date: {billDetails.dueDate}</div>
          <div>Status: {billDetails.status}</div>
          <div>Total Amount: Php {billDetails.amount}</div>
          <div>Description: {billDetails.description}</div>
        </div>

        <div>
          <button
            className="small-form-button w-full font-bold mt-4"
            onClick={() => navigate(`/payment-confirmed/${id}`)}
            disabled={true}
          >
            button is disabled pending paymongo integration
          </button>
        </div>

        <div className="mt-4">
          <label>or Upload Deposit Slip</label>
          <input type="file" onChange={handleUpload} />
          <div>{progress > 0 && <progress value={progress} max="100" />}</div>
        </div>

        <div className="mt-8">
          <div>User Remarks </div>

          <InputBox
            name="remarks"
            onChange={handleChange}
            placeholder={"Enter your remarks here"}
            value={remarks}
          />

          <div className="mt-8 w-full">
            <button
              className="small-form-button w-full font-bold"
              onClick={handleSubmit}
              disabled={billDetails.status !== "unpaid"}
            >
              {isProcessing ? "Submitting" : "Submit Deposit Slip"}
            </button>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default BillingDetails;

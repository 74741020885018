import React from "react";
import NoLogo from "../../assets/nologo.gif";

export default function SupplierInformationPage({ data, setViewMode }) {
  const feedbacks = data.feedbacks || [];

  return (
    <div className="bg-white p-4 rounded-lg w-full min-h-screen text-xs text-left relative flex items-start flex-col lg:flex-row gap-2 lg:gap-4">
      <button onClick={() => setViewMode(false)} className="close-button">
        Close
      </button>


      <div className="mt-8 min-w-80">
      {/* <button className="blue-button">Edit</button> */}
        <div className="header">Supplier Information Page</div>
        <img
          src={data.logo || NoLogo}
          className="w-36 aspect-square object-contain"
          alt=""
        />
        <div className="flex items-center justify-between">
          <div>Supplier Name</div>
          <div>{data.name}</div>
        </div>
        <div className="flex items-start justify-between">
          <div>Address</div>
          <div className="w-60 text-right">{data.address}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>Contact Number</div>
          <div>{data?.phone || "Not Set"}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>Email Address</div>
          <div>{data?.email || "Not Set"}</div>
        </div>

        <div className="mt-4">
          <div className="font-bold">List of Services</div>
          <div>
            {data.services.map((service, index) => (
              <div key={index}>
                <ul>
                  <li className="capitalize list-disc">{service}</li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full pt-8 space-y-2">
        <div className="header">Customer Feedbacks</div>
        {feedbacks.length === 0 ? (
          <div>No feedbacks yet</div>
        ) : (
          feedbacks.map((feedback, index) => (
            <div key={index} className="comment">
              <div className="font-bold">{feedback.name}</div>
              <div>{feedback.comment}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocs } from "../../utils/firebase/Firebase.utils";
import SupplierContactCard from "./SupplierContactCard";

export default function Suppliers() {
  const [suppliers, setSuppliers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    const fetchSuppliers = async () => {
      const suppliers = await getAllDocs("suppliers");
      //sort by name
      suppliers.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setSuppliers(suppliers);
      setIsLoading(false);
    };
    fetchSuppliers();
  }, []);

  const servicesTypes = suppliers.map((supplier) => supplier.services[0]);
  //unique services
  const uniqueServices = [...new Set(servicesTypes)];

  const filteredItems = suppliers.filter((supplier) => {
    return (
      supplier?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supplier?.services[0].toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <UserScreenWindow label="Suppliers">
      <div className="min-h-screen">
        <div className="header uppercase text-sm">Suppliers</div>

        <div className="mt-4">
          <div className="p-2 rounded-lg bg-white">
            <input
              type="search"
              className="w-full p-2 border border-gray-200 rounded-lg"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="mt-8 flex flex-col space-y-2">
            {filteredItems.map((supplier, index) => (
              <div key={index}>
                <SupplierContactCard supplier={supplier} />
              </div>
            ))}
          </div>
          <div className="mt-16">
            <div className="header">DISCLAIMER</div>
            <p className="text-justify p-2 text-xs text-gray-600 mt-auto">
              <span className="text-blue-900 font-bold">
                TALI HOME OWNERS GROUP (THOG)
              </span>{" "}
              through this app provides a platform that connects users with
              suppliers. However, THOG is not a party to any transaction or
              agreement between users and suppliers. As such, we do not endorse,
              guarantee, or assume any responsibility for the products,
              services, or actions of any supplier. Users are advised to
              exercise due diligence and caution in their interactions with
              suppliers. THOG shall not be liable for any losses or damages
              resulting from transactions made through the platform. By using
              our platform, you acknowledge that transactions are conducted at
              your own risk.
            </p>
          </div>
        </div>
      </div>
    </UserScreenWindow>
  );
}

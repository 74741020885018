import React, { useEffect } from "react";
import UserScreenWindow from "./UserScreenWindow";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import ContactCard from "./ContactCard";

function SOS() {
  const [emergencyContacts, setEmergencyContacts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    const fetchContacts = async () => {
      await getAllDocsSnap("contacts", (data) => {
        const filteredData = data.filter(
          (contact) => contact.category === "Emergency Hotlines"
        );
        //sort by name
        filteredData.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setEmergencyContacts(filteredData);
        setIsLoading(false);
      });
    };
    fetchContacts();
  }, []);

  const filteredItems = emergencyContacts.filter((contact) => {
    return (
      contact?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact?.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <UserScreenWindow label="Emergency Information">
      <div className="bg-white rounded-xl p-2 mt-4">
        <div className="text-center text-sm font-bold text-blue-900">
          Emergency Hotlines
        </div>
      </div>

      <div className="text-lg mt-8 text-center">
        <div className="font-bold text-red-800">
          For Emergency Assistance Please Call
        </div>
        <div className="text-center">
          <div>Security Office</div>
          <a href="tel:+639053387440">
            <div className="bg-red-700 text-white p-4 rounded-lg text-center w-full">
              CALL NOW
            </div>
          </a>
        </div>
      </div>

      <div className="mt-4">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <div className="mt-4">
              <div className="p-2 rounded-lg bg-white">
                <input
                  type="search"
                  className="w-full p-2 border border-gray-200 rounded-lg"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {filteredItems.map((contact, index) => (
              <ContactCard key={index} contact={contact} />
            ))}
          </div>
        )}
      </div>
    </UserScreenWindow>
  );
}

export default SOS;

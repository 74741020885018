import React from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserDashboard from "../User/UserDashboard";
import UserNavBar from "../User/UserNavBar";
import { motion, AnimatePresence } from "framer-motion";

function Dashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  if (currentUser?.roles?.length < 1) {
    return (
      <div className=" flex items-center justify-center font-semibold text-lg flex-col h-screen">
        <div>
          Congratulations, your account has been created. However, please wait
          for the activation of your access rights.
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          onClick={() => {
            navigate("/login");
          }}
        >
          Log Out
        </button>
      </div>
    );
  }

  if (currentUser?.roles?.includes("user")) {
    return (
      <AnimatePresence>
        <div className="max-w-[450px] w-screen m-auto bg-gray-100">
          <motion.div className="relative max-w-[450px] m-auto w-screen">
            <div className="pb-28">
              <Outlet />
            </div>
          </motion.div>
          <div className="fixed left-0 bottom-0 mx-auto w-full ">
            <UserNavBar />
          </div>
        </div>
      </AnimatePresence>
    );
  }

  return (
    <div className="min-h-screen h-screen bg-gray-100">
      <div className="h-auto">
        <Header />
        <Navbar />
        <div className="px-2 lg:px-2 2xl:px-12 py-2 bg-gray-100 h-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import TenantDetails from "./TenantDetails";
import Modal from "../Components/Modal";
import TenantCard from "./TenantCard";
import { Link } from "react-router-dom";
import BentoContainer from "../../../components/BentoContainer";
import BentoTable from "../../../components/BentoTable";

function AllTenants({ searchTerm }) {
  const [tenants, setTenants] = React.useState([]);
  const [tenant, setTenant] = React.useState({});
  const [viewMode, setViewMode] = React.useState(false);
  const [residents, setResidents] = React.useState([]);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);

  const tableHeaders = [
    "Lot Owner",
    "Phase Block and Lot Number",
    "Tenant Name",
    "Occupants",
    "Address",
    "Lease Start",
    "Lease End",
    "Documents",
  ];

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        setResidents(residents);
      });
    };

    fetchResidents();
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      getAllDocsSnap("tenants", (tenants) => {
        const mappedTenants = tenants.map((tenant) => {
          const unitOwner = residents.find(
            (resident) => resident.identifier === tenant.lot
          );
          return {
            ...tenant,
            unitOwner: unitOwner?.owner1 || "N/A",
            tenantName: `${tenant.firstName} ${tenant.lastName}`,
            documents: "View",
          };
        });
        setTenants(mappedTenants);
      });
    };

    fetchTenants();
  }, [residents]);

  const handleViewMode = (tenant) => {
    setTenant(tenant);
    setViewMode(true);
  };

  const filteredTenants = tenants.filter(
    (tenant) =>
      tenant.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tenant.unitOwner.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedItems = filteredTenants.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div className=" mt-4 text-xs">
      <BentoTable
        label="All Lease Tenants"
        data={filteredTenants}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="">
          {slicedItems.map((tenant, index) => (
            <div
              key={index}
              className="border-b-2 border-gray-200"
              onClick={() => handleViewMode(tenant)}
            >
              <Link to={`/tenant/${tenant.id}?detail=Basic+Information`}>
                <TenantCard tenant={tenant} />
              </Link>
            </div>
          ))}
        </div>
      </BentoTable>
    </div>
  );
}

export default AllTenants;

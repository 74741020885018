import React from "react";
import noImage from "../assets/nologo.gif";

function SupplierContactCard({ supplier }) {
  return (
    <div className="border border-gray-400 bg-white p-2 rounded-lg w-full text-xs text-gray-600 ">
      <div className="flex items-center gap-2">
        <img
          src={supplier.logo || noImage}
          className="w-16 min-h-26 object-cover"
          alt=""
        />
        <div className="flex flex-col items-start">
          <div className="font-bold text-center text-blue-900 mt-2">
            {supplier.name}
          </div>
          <div className="text-center font-bold">
            <a href={`tel:${supplier.phone}`}>{supplier.phone}</a>
          </div>

          <div>{supplier.services[0]}</div>
          <div className="text-left">
            {supplier.address === "not indicated" ? "" : supplier.address}
          </div>
        </div>
      </div>
      <div className="text-right">
        <button className="blue-button mt-2">
          <a href={`tel:${supplier.phone}`}>Call</a>
        </button>
      </div>
    </div>
  );
}

export default SupplierContactCard;

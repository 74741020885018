import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import { useSelector } from "react-redux";
import PieChart from "../Charts/PieChart";
import PieChartDashboard from "../Charts/PieChart";
import SimpleBarChartDashboard from "../Charts/SimpleBarChartDashboard";
import BasicDateCalendar from "../Charts/Calendar";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllDocsSnap,
  getAllDocs,
  addNewDoc,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSearchParams } from "react-router-dom";
import Modal from "./Components/Modal";
import SplashScreen from "./SplashScreen";
import PageModal from "./Components/PageModal";
import PropertyOverviewReport from "./AdminDashboard/PropertyOverviewReport";
import BentoContainer from "../../components/BentoContainer";
import { PieChartV1 } from "../../components/PieChartV1";
import { CardDescription } from "../../components/card";
import { convertTimeStamptoDate } from "../../utils/EleghoUtils";

import SelectInput from "../Dashboard/Components/SelectInput";
import InputBox from "./Components/InputBox";
import toast from "react-hot-toast";
import TodosForm from "../Todos/TodosForm";

function AdminDashboard() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const splash = searchParams.get("splash");
  const [showModal, setShowModal] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [selectedJobOrderLink, setSelectedJobOrderLink] =
    React.useState("newJobOrder");
  const firstName = currentUser.displayName;
  const [numberOfUnits, setNumberOfUnits] = React.useState(0);
  const [occupiedUnits, setOccupiedUnits] = React.useState(0);
  const [gatepass, setGatepass] = React.useState(0);
  const [visitorPass, setVisitorPass] = React.useState(0);
  const [activeReport, setActiveReport] = useState("propertyOverview");
  const [viewMode, setViewMode] = useState(false);
  const [workPermits, setWorkPermits] = useState(0);
  const [concerns, setConcerns] = useState(0);
  const [pendingGatePass, setPendingGatePass] = useState(0);
  const [pendingVisitorPass, setPendingVisitorPass] = useState(0);
  const [pendingWorkPermits, setPendingWorkPermits] = useState(0);
  const [pendingConcerns, setPendingConcerns] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [addTodo, setAddTodo] = useState(false);
  const [formData, setFormData] = useState({});
  const [pendingTodos, setPendingTodos] = useState([]);

  useEffect(() => {
    if (splash === "true") {
      setShowModal(true);
    }
  }, [splash]);

  useEffect(() => {
    const fetchTodos = async () => {
      await getAllDocsSnap("todos", (todos) => {
        const pending = todos.filter(
          (todo) => todo.status === "pending" && todo.user === currentUser.uid
        );
        setPendingTodos(pending);
      });
    };
    fetchTodos();
  }, []);

  useEffect(() => {
    const fetchgatepass = async () => {
      const gatepass = await getAllDocsSnap("gatePass", (gatepass) => {
        const pendingGatePass = gatepass.filter(
          (item) => item.status === "pending"
        );
        setPendingGatePass(pendingGatePass);
        setGatepass(pendingGatePass.length);
      });
    };

    const fetchVisitorPass = async () => {
      const visitorPass = await getAllDocsSnap("guests", (visitorPass) => {
        const pendingVisitorPass = visitorPass.filter(
          (item) => item.status === "pending"
        );
        setPendingVisitorPass(pendingVisitorPass);
        setVisitorPass(pendingVisitorPass.length);
      });
    };

    const fetchWorkPermits = async () => {
      const workPermits = await getAllDocsSnap("workPermits", (workPermits) => {
        const pendingWorkPermits = workPermits.filter(
          (item) => item.status === "Pending"
        );
        setPendingWorkPermits(pendingWorkPermits);
        setWorkPermits(pendingWorkPermits.length);
      });
    };

    const fetchConcerns = async () => {
      const concerns = await getAllDocsSnap("concerns", (concerns) => {
        const pendingConcerns = concerns.filter(
          (item) => item.status === "Pending"
        );
        setPendingConcerns(pendingConcerns);
        setConcerns(pendingConcerns.length);
      });
    };
    fetchgatepass();
    fetchWorkPermits();
    fetchConcerns();
    fetchVisitorPass();
  }, []);

  useEffect(() => {
    const fetchResidents = async () => {
      const residentList = getAllDocsSnap("residents", (residents) => {
        setNumberOfUnits(residents.length);
        const occupiedUnits = residents.filter(
          (resident) => resident.numberOfOccupants > 0
        );
        setOccupiedUnits(occupiedUnits.length);
        setIsLoading(false);
      });
    };
    fetchResidents();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const notifications = {
    notifications: 10,
    todos: 15,
  };

  const forApprovals = {
    gatepass: 24,
    visitorPass: 12,
    maintenanceRequest: 5,
    otherRequests: 3,
  };

  const currentDateTime = new Date();

  const propertyOverview = {
    totalUnits: numberOfUnits,
    totalOccupied: occupiedUnits,
    totalVacant: numberOfUnits - occupiedUnits,
  };

  const todoList = [
    { id: 1, title: "Check your approval list and request", priority: "High" },
    {
      id: 2,
      title: "Check your approval list and request",
      priority: "High",
    },
    {
      id: 3,
      title: "Check your approval list and request",
      priority: "High",
    },
  ];

  const activeLinkStyle = "text-green-600 border-b-2 border-green-600 p-b-2";

  const handleViewReport = () => {
    setActiveReport("propertyOverview");
    setViewMode(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSearchParams({ splash: false });
  };

  const data = {
    title: "Occupancy Chart",
    cardDescription: "As of " + currentDateTime.toLocaleString(),
    chartConfig: {
      Houses: {
        label: "Occupancy",
      },
      Occupied: {
        label: "Occupied",
        color: "#ffab00",
      },
      Vacant: {
        label: "Vacant",
        color: "#4285f4",
      },
    },
    chartData: [
      {
        label: "Vacant",
        data: numberOfUnits - occupiedUnits,
        fill: "var(--color-Occupied)",
      },
      { label: "Occupied", data: occupiedUnits, fill: "var(--color-Vacant)" },
    ],
    total: numberOfUnits,
  };

  const numberOfWorkItems = gatepass + visitorPass + workPermits + concerns;

  

  const mappedGatePass = pendingGatePass.map((item) => {
    return {
      id: item.id,
      title: `Gate Pass for ${item.deliveryCompany}`,
      location: item.identifier,
      type: "Gate Pass",
      dateTime: convertTimeStamptoDate(item.requestDateTime).toLocaleString(),
    };
  });

  const mappedWorkPermits = pendingWorkPermits.map((item) => {
    return {
      id: item.id,
      title: item.workPermitType,
      location: item.identifier,
      type: "Work Permit",
      dateTime: item.createdAt,
    };
  });

  const mappedVisitorPass = pendingVisitorPass.map((item) => {
    return {
      id: item.id,
      title: `Visitor Pass`,
      location: item.linkedIdentifiers[0],
      type: "Visitor Pass",
      dateTime: new Date(item.date).toLocaleString(),
    };
  });

  const mappedConcerns = pendingConcerns.map((item) => {
    return {
      id: item.id,
      title: item.concern,
      location: item.identifier,
      type: "Concerns",
      dateTime: item.createdAt,
    };
  });

  const workItems = [
    ...mappedGatePass,
    ...mappedVisitorPass,
    ...mappedWorkPermits,
    ...mappedConcerns,
  ].slice(0, 10);

  //sort workitems by dateTime
  workItems.sort((a, b) => {
    return new Date(b.dateTime) - new Date(a.dateTime);
  });

  const handleLapse = (dateTime) => {
    const totalMilliseconds = currentDateTime - new Date(dateTime);
    const days = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (totalMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    let result;
    if (days > 0) {
      result = days.toString() + " days ago";
    } else {
      result = hours.toString() + " hours ago";
    }

    return result;
  };

  const handleNavigate = (type) => {
    if (type === "Gate Pass") {
      navigate("/security?type=Gate+Pass");
    }

    if (type === "Visitor Pass") {
      navigate("/security?type=Guest+Pass");
    }

    if (type === "Work Permit") {
      navigate("/property-management?type=Work+Permits");
    }

    if (type === "Concerns") {
      navigate("/property-management?type=Concerns");
    }
  };

  const handleAddtodo = () => {
    setAddTodo(!addTodo);
  };

  const handleComplete = async (id) => {
    await setDocData("todos", id, { status: "completed" });
    toast.success("Todo marked as completed");
  };

  return (
    <div>
      {showModal && (
        <Modal>
          <div>
            <SplashScreen
              setShowModal={setShowModal}
              setClose={handleCloseModal}
            />
          </div>
        </Modal>
      )}

      {addTodo && (
        <Modal>
          <TodosForm setAddTodo={setAddTodo} />
        </Modal>
      )}

      {/* Welcome Band */}
      <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300">
        <div className="text-left">
          <div className="text-2xl font-semibold text-blue-900">
            Welcome Back {firstName}!
          </div>
          <div className="text-xs text-gray-600">
            You have{" "}
            <span className="font-bold text-red-700">
              {currentUser.notifications}
            </span>
            {" unread "}
            notifications and{" "}
            <span className="font-bold text-red-700">
              {numberOfWorkItems}
            </span>{" "}
            of work items. It is a lot of work today!
          </div>
        </div>

        {/* <div className="rounded-lg border border-gray-300 px-4 py-2 hidden lg:flex items-center justify-center">
          <FiEdit3 className="text-2xl text-blue-900" />
          Edit
        </div> */}
      </div>

      {/* container for the grid */}
      <div className="flex items-start justify-center text-blue-900 flex-wrap">
        {/* first grid */}
        <div>
          <BentoContainer
            label="For Approval"
            subtitle={currentDateTime.toLocaleString()}
          >
            <div className="sm:w-[350px] w-screen p-2 flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-around text-blue-900">
                  <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative">
                    <div className="uppercase text-xs font-semibold">
                      Gate pass
                    </div>
                    <div className="text-3xl font-bold">{gatepass}</div>
                    <div className="absolute text-xs right-3 bottom-0 underline">
                      <Link to={`/security?type=Gate+Pass`}>View All</Link>
                    </div>
                  </div>
                  <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative text-green-500">
                    <div className="uppercase text-xs font-semibold">
                      Visitors Pass
                    </div>
                    <div className="text-3xl font-bold">{visitorPass}</div>
                    <div className="absolute text-xs right-3 bottom-0  underline">
                      <Link to={`/security?type=Guest+Pass`}>View All</Link>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-around text-blue-900">
                  <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative">
                    <div className="uppercase text-xs font-semibold">
                      Work Permits
                    </div>
                    <div className="text-3xl font-bold">{workPermits}</div>
                    <div className="absolute text-xs right-3 bottom-0 underline">
                      <Link to={`/property-management?type=Work+Permits`}>
                        View All
                      </Link>
                    </div>
                  </div>
                  <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative text-green-500">
                    <div className="uppercase text-xs font-semibold">
                      Other Concerns and Requests
                    </div>
                    <div className="text-3xl font-bold">{concerns}</div>
                    <div className="absolute text-xs right-3 bottom-0  underline">
                      <Link to={`/property-management?type=Concerns`}>
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BentoContainer>

          <BentoContainer
            label={"Job Orders"}
            subtitle={currentDateTime.toLocaleString()}
          >
            <div className="w-full border border-gray-300 rounded-lg overflow-hidden px-4 py-1">
              <div className="text-left text-xs">
                {"As of " + currentDateTime.toLocaleString()}
              </div>
              <div className="text-xs flex items-center justify-start gap-4">
                <div
                  className={`cursor-pointer
                      ${
                        selectedJobOrderLink === "newJobOrder"
                          ? activeLinkStyle
                          : ""
                      }
                          `}
                  onClick={() => setSelectedJobOrderLink("newJobOrder")}
                >
                  New Job Order
                </div>
                <div
                  className={`cursor-pointer
                          ${
                            selectedJobOrderLink === "newPendingOrder"
                              ? activeLinkStyle
                              : ""
                          }
                              `}
                  onClick={() => setSelectedJobOrderLink("newPendingOrder")}
                >
                  New Pending Order
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-4">
                {selectedJobOrderLink === "newJobOrder"
                  ? workItems.map((jobOrder) => (
                      <div
                        key={jobOrder.id}
                        className=" relative flex items-start justify-between border border-gray-300 rounded-lg p-2"
                      >
                        <div
                          className="text-xs absolute bottom-0 right-2 underline cursor-pointer"
                          onClick={() => handleNavigate(jobOrder.type)}
                        >
                          View All
                        </div>
                        <div className="flex flex-col items-start justify-start text-xs">
                          <div className="font-bold">{jobOrder.title}</div>
                          <div>{jobOrder.location}</div>
                          <div className="bg-pink-300 px-2 py-1 rounded-md text-[10px]">
                            {jobOrder.type}
                          </div>
                        </div>
                        <div className="text-xs">
                          {
                            // compute for lapsed days
                            handleLapse(jobOrder.dateTime)
                          }
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </BentoContainer>
        </div>
        {/* end of first grid */}

        {/* second grid */}
        <div className="w-[600px] px-2 flex flex-col items-start justify-start gap-2">
          <BentoContainer
            label={"Property Overview"}
            subtitle={currentDateTime.toLocaleString()}
          >
            <div className="border border-gray-300 rounded-lg text-left p-1 relative w-full">
              <div className="absolute text-xs p-2 top-0 right-0 underline">
                <button className="underline" onClick={handleViewReport}>
                  View Detailed Report
                </button>
              </div>

              <div className="uppercase font-bold">
                Total Homes: {propertyOverview.totalUnits}
              </div>
              <div className="h-auto w-auto mt-8 align-center">
                <PieChartV1 data={data} />
              </div>
            </div>
          </BentoContainer>

          <BentoContainer label={"Dues Collection Overview"} subtitle={""}>
            <div className="w-full border border-gray-300 rounded-lg p-2 flex flex-col gap-2">
              <div className="h-96 w-auto">
                <SimpleBarChartDashboard />
              </div>
            </div>
          </BentoContainer>
        </div>
        {/* end of second grid */}

        {/* third grid */}
        <div className="w-[350px]">
          <BentoContainer
            label={"Calendar"}
            subtitle={currentDateTime.toLocaleString()}
          >
            <div className="border-2 bordergra30 p-2 rounded-lg w-fit">
              <BasicDateCalendar />
            </div>
          </BentoContainer>

          <BentoContainer
            label={"To Dos"}
            subtitle={currentDateTime.toLocaleString()}
          >
            <div>
              <div className="flex items-center justify-between mt-4">
                <button className="blue-button" onClick={handleAddtodo}>
                  Add a To do
                </button>
                <div
                  className="text-xs underline cursor-pointer"
                  onClick={() => navigate("/todos")}
                >
                  View All
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                {pendingTodos.slice(0, 10).map((todo) => (
                  <div
                    key={todo.id}
                    className="border-2 border-gray-300 rounded-lg text-left p-2"
                  >
                    <div>{todo.name}</div>
                    <div className="bg-yellow-400 px-2 py-1 rounded-lg w-fit text-xs">
                      {todo.priority}
                    </div>

                    <div className="mt-4">
                      <div
                        className="text-xs text-gray-600 underline cursor-pointer"
                        onClick={() => handleComplete(todo.id)}
                      >
                        Mark as Completed
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </BentoContainer>
        </div>
        {/* end of third grid */}
      </div>

      {viewMode && (
        <PageModal>
          <PropertyOverviewReport data="" setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}

export default AdminDashboard;

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useMotionValue } from "framer-motion";
import { SlArrowLeftCircle } from "react-icons/sl";
import { SlArrowRightCircle } from "react-icons/sl";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import PageModal from "../Dashboard/Components/PageModal";

function Announcements() {
  const containerRef = useRef(null);
  const [dragging, setDragging] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [mobileWidth, setMobileWidth] = useState(380);
  const [announcementArray, setAnnouncementArray] = useState([]);
  const [viewPhoto, setViewPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      await getAllDocsSnap("announcements", (data) => {
        data.sort = (a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        };

        const publishedData = data.filter(
          (item) => item.status === "published"
        );

        setAnnouncementArray(publishedData);
      });
    };
    fetchAnnouncements();
  }, []);

  const photos = announcementArray;

  // const photos = [
  //   {
  //     id: 1,
  //     url: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1723452295/elegho/ads/Screenshot_2024-08-12_164247_s1q2va.png",
  //   },
  //   {
  //     id: 2,
  //     url: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1723452296/elegho/ads/Screenshot_2024-08-12_164338_etxiea.png",
  //   },
  //   {
  //     id: 3,

  //     url: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1723452296/elegho/ads/Screenshot_2024-08-12_164318_mva6sw.png",
  //   },
  // ];

  const dragX = useMotionValue(0);

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = () => {
    setDragging(false);

    if (dragX.get() < -10) {
      if (currentIndex === photos.length - 1) {
        return;
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else if (dragX.get() > 10) {
      if (currentIndex === 0) {
        return;
      } else {
        setCurrentIndex((prev) => prev - 1);
      }
    }
  };

  //set auto slide every 2 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex === photos.length - 1 || photos.length === 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleViewAnnouncement = (photo) => {
    setViewPhoto(true);
    setSelectedPhoto(photo);
  };

  return (
    <div className="w-full">
      {viewPhoto && (
        <PageModal>
          <div className="page relative">
            <button
              onClick={() => setViewPhoto(false)}
              className="close-button"
            >
              Close
            </button>
            <div className="text-left text-gray-400 mb-4">
              {selectedPhoto.createdAt}
            </div>
            <div className="font-bold text-blue-900  mb-4">
              {selectedPhoto.title}
            </div>

            <img src={selectedPhoto.poster} alt={selectedPhoto.title} />
          </div>
        </PageModal>
      )}

      <motion.div
        className="w-full aspect-video object-cover rounded-xl flex items-center overflow-hidden relative"
        ref={containerRef}
      >
        <motion.div
          className="flex items-center cursor-grab active:cursor-grabbing"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          style={{
            x: dragX,
          }}
        >
          {photos.map((photo, index) => (
            <div
              className="cursor-pointer"
              onClick={() => handleViewAnnouncement(photo)}
              key={index}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: "transform 0.5s ease",
                width: `${mobileWidth}px`,
              }}
            >
              <div className="z-10 bg-gray-400">{photo.title}</div>
              <img
                src={photo.poster}
                alt={photo.title}
                className="object-scale-down rounded-xl"
                // stop the drag event from bubbling up to the parent
                onDragStart={(e) => e.preventDefault()}
                onTouchStart={(e) => e.preventDefault()}
              />
            </div>
          ))}
        </motion.div>

        {/* create dot indicators */}
        {photos.length > 1 && (
          <div className="flex items-center justify-center gap-2 absolute bottom-4 p-2 rounded-xl bg-opacity-60 left-1/2 -translate-x-1/2 bg-black w-fit">
            {photos.map((photo, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentIndex ? "bg-leechiuOrange" : "bg-white"
                }`}
              ></div>
            ))}
          </div>
        )}

        {/* create buttons for navigation */}
        {photos.length > 1 && (
          <>
            <div className="absolute top-1/2 left-0 transform -translate-y-1/2 max-sm:hidden ">
              <button
                className="rounded-full bg-opacity-50"
                onClick={() => {
                  if (currentIndex === 0) {
                    return;
                  } else {
                    setCurrentIndex((prev) => prev - 1);
                  }
                }}
              >
                <SlArrowLeftCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 ml-2" />
              </button>
            </div>
            <div className="absolute top-1/2 right-0 transform -translate-y-1/2 max-sm:hidden">
              <button
                className="rounded-full bg-opacity-50 "
                onClick={() => {
                  if (currentIndex === photos.length - 1) {
                    return;
                  } else {
                    setCurrentIndex((prev) => prev + 1);
                  }
                }}
              >
                <SlArrowRightCircle className="text-white h-8 w-8 opacity-50 hover:opacity-100 mr-2" />
              </button>
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
}

export default Announcements;

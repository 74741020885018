import React from "react";

function ContactCard({ contact }) {
  return (
    <div className="border-b border-gray-200 py-2 text-xs bg-white p-2">
      <div className="font-bold text-blue-900">{contact.name}</div>
      <div>{contact.title}</div>

      <div>{contact.email}</div>
      <div>{contact.contactNumber}</div>

      <div className="text-right mt-2
      ">
        <a href={"tel:" + contact.contactNumber} className="blue-button">
          Call
        </a>
      </div>
    </div>
  );
}

export default ContactCard;

import React from "react";
import InputBox from "./InputBox";
import ButtonBlue from "./ButtonBlue";
import { useEffect, useState } from "react";
import {
  signupWithEmail,
  checkUser,
} from "../../utils/firebase/Firebase.utils";
import { updateUserDisplayName } from "../../utils/firebase/Firebase.utils";
import { createUserDoc } from "../../utils/firebase/Firebase.utils";

import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/TaliConnectLogo.jpeg";

function SignupForm() {
  const [formData, setFormData] = useState({});

  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkDomainEmail = (email) => {
    const domain = email.split("@")[1];
    if (domain === "tali.com") {
      return true;
    } else {
      return false;
    }
  };

  const checkPassword = (password, confirmPassword) => {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordFormat = (password) => {
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
    return regex.test(password);
  };

  const createUserDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = await createUserDoc(userAuth, additionalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const { displayName, email, password, confirm_password } = formData;

    // if (!email.endsWith("@tali.com")) {
    //   alert("Please use a Tali email address (@tali.com)");
    //   setIsProcessing(false);
    //   return;
    // }

    if (password !== confirm_password) {
      alert("Passwords do not match");
      setIsProcessing(false);
      return;
    }

    const passwordCheck = checkPasswordFormat(password);
    if (!passwordCheck) {
      alert(
        "Please choose a password of at least 8characters long which is a combination of upppercase, lowercase, numbers, and special character"
      );
      setIsProcessing(false);
      return;
    }

    // signUpUsingEmail(email, password);
    const signUp = async () => {
      try {
        const res = await signupWithEmail(email, password);
        if (res) {
          const userCheck = await checkUser(res.user);

          if (!userCheck) {
            await createUserDoc(res.user, { displayName, roles: [] });
            dispatch(
              loadUser({ email, displayName, uid: res.user.uid, roles: [] })
            );
            navigate("/dashboard");
          } else {
            alert("User already exists, sign in instead");
          }
        }
      } catch (error) {}
    };
    signUp();
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 w-[350px]">
      <div className="flex flex-col items-start justify-around gap-4">
        <div className="text-lg font-bold uppercase w-full text-center">
          <img src={Logo} alt="" className="w-20 h-20 m-auto" />
          <div className="text-gray-600 font-bold text-xs capitalize mt-4">
            Powered by
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
              alt=""
              className="w-16 m-auto"
            />
          </div>
        </div>
        <div className="text-center w-full">Enter Your Details Below</div>
        <div className="w-full">
          <form className="w-full " onSubmit={handleSubmit}>
            <InputBox
              label="Full Name"
              type="text"
              placeholder="Enter your full name"
              onChange={handleChange}
              name="displayName"
            />
            <InputBox
              label="Email Address"
              type="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              name="email"
            />
            <InputBox
              label="Password"
              type="password"
              placeholder="Enter your password"
              onChange={handleChange}
              name="password"
            />
            <InputBox
              label="Confirm Password"
              type="password"
              placeholder="Confirm your password"
              onChange={handleChange}
              name="confirm_password"
            />
            <div className="mt-8">
              <ButtonBlue
                label={isProcessing ? "Signing up..." : "Sign Up"}
                type="submit"
              />
            </div>

            <div>
              Already have an account?{" "}
              <Link to="/login" className="text-blue-600">
                Log in
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;

import React from "react";
import InputBox from "../Components/InputBox";
import { useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../../utils/firebase/Firebase.utils";
import { useEffect } from "react";

export default function ResidentOccupantInformationPage({ occupantId }) {
  const { id } = useParams();
  const [occupant, setOccupant] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [occupants, setOccupants] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);

  useEffect(() => {
    const fetchOccupant = async () => {
      await getDocDataSnap("residents", id, (data) => {
        const occupantItem = data.occupantsName.find(
          (o) => o.id === occupantId
        );
        setOccupants(data.occupantsName || []);
        setOccupant(occupantItem);
        setFormData(occupantItem);

        setIsLoading(false);
      });
    };

    fetchOccupant();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    await setDocData("residents", id, {
      occupantsName: occupants.map((o) =>
        o.id === occupantId ? { ...formData } : o
      ),
    });
    setIsProcessing(false);
    setEditMode(false);
  };

  return (
    <div className="">
      <div className="mt-4">
        <form action="" className="mt-4" onSubmit={handleSubmit}>
          <div className="flex items-start gap-4 flex-wrap">
            <div className="lg:w-[500px] w-full space-y-4">
              <div>
                <button
                  type="button"
                  onClick={handleEditMode}
                  className={editMode ? "underline" : "blue-button"}
                >
                  {editMode ? "Cancel Edit" : "Edit Information"}
                </button>
              </div>
              <InputBox
                name="firstName"
                label="First Name"
                value={formData.firstName}
                disabled={!editMode}
                placeholder={"First Name"}
                onChange={handleChange}
              />

              <InputBox
                name="middleName"
                label="Middle Name"
                value={formData.middleName}
                disabled={!editMode}
                placeholder={"Middle Name"}
                onChange={handleChange}
              />

              <InputBox
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                disabled={!editMode}
                placeholder="Last Name"
                onChange={handleChange}
              />

              <InputBox
                name="birthday"
                label="Birthday"
                type="date"
                value={formData.birthday}
                disabled={!editMode}
                placeholder={"Birthday"}
                onChange={handleChange}
              />

              <InputBox
                name="relationship"
                label="Relationship to the Owner"
                value={formData.relationship}
                disabled={!editMode}
                placeholder={"Relationship to the Owner"}
                onChange={handleChange}
              />

              {editMode && (
                <div>
                  <button className="blue-button w-full lg:w-fit">
                    {isProcessing ? "Saving..." : "Save"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  getAllDocsSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import WorkPermitCards from "./WorkPermits/WorkPermitCards";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PageModal from "./Components/PageModal";
import WorkPermitDetails from "./WorkPermits/WorkPermitDetails";

function WorkPermits() {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState("Pending");
  const [workPermits, setWorkPermits] = useState([]);
  const [filteredWorkPermitApplications, setFilteredWorkPermitApplications] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const fetchWorkPermits = async () => {
      await getAllDocsSnap("workPermits", (data) => {
        // arrange by createdAt
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setWorkPermits(data);
        setIsLoading(false);
      });
    };
    fetchWorkPermits();
  }, []);

  const workPermitApplications = workPermits;

  useEffect(() => {
    if (activeFilter === "All") {
      setFilteredWorkPermitApplications(workPermitApplications);
    } else {
      setFilteredWorkPermitApplications(
        workPermitApplications.filter(
          (application) => application.status === activeFilter
        )
      );
    }
  }, [activeFilter]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filters = ["All", "Pending", "Approved", "Returned"];

  const tableHeaders = [
    "Lot No.",
    "Name",
    "Request No.",
    "Status",
    "Date Applied",
    "Date Resolved",
    "No. of Days Past",
    "Documents",
    "Description",
    "Remarks",
    "Action Taken",
  ];

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    return date;
  };

  //compute the number of days past since the request was made
  const computeDaysPast = (dateString) => {
    //convert date string to date object
    const date = new Date(dateString);

    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const newFilteredWorkPermitApplications =
    filteredWorkPermitApplications.filter(
      (application) =>
        application.identifier
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        application.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleApproval = async (application) => {
    if (window.confirm("Are you sure you want to approve this request?")) {
      const docObject = {
        ...application,
        status: "Approved",
        approvedAt: new Date().toLocaleString(),
      };
      await setDocData("workPermits", application.id, docObject);
      toast.success("Request Approved Successfully");
    }
  };

  const handleDecline = async (application) => {
    if (window.confirm("Are you sure you want to decline this request?")) {
      const docObject = {
        ...application,
        status: "Rejected",
        approvedAt: new Date().toLocaleString(),
      };
      await setDocData("workPermits", application.id, docObject);
      toast.error("Request Declined Successfully");
    }
  };

  const handleViewDocuments = (application) => {
    navigate(`/dashboard/work-permits/${application.id}`);
  };

  const handleViewMode = (application) => {
    setViewMode(!viewMode);
    setSelectedApplication(application);
  };

  return (
    <div className=" text-left">
      <div className="text-sm font-bold text-blue-900 text-left">
        Request for Approvals and Work Permits
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-4 mt-2">
          {filters.map((filter, index) => (
            <div
              key={index}
              className={`cursor-pointer rounded-full px-4 py-1 text-sm border-2 border-gray-300 ${
                activeFilter === filter ? "active-pill" : "inactive-pill"
              }`}
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 border-2 border-gray-300 p-2 rounded-lg flex items-center justify-between">
        <div>
          <input
            type="search"
            name="search"
            className="border-2 border-gray-300 px-4 py-2 w-60 rounded-lg"
            placeholder="Search Lot No. or Name"
            onChange={handleSearch}
          />
        </div>
        <div>Filter</div>
      </div>

      {/* cards visible when small screen */}
      <div className=" space-y-2 mt-8">
        {newFilteredWorkPermitApplications.map((application, index) => (
          <div
            className=""
            key={index}
            onClick={() => handleViewMode(application)}
          >
            <WorkPermitCards application={application} />
          </div>
        ))}
      </div>

      {/* table visible only when in desktop mode */}
      {/* <div className="text-xs hidden ">
        <table className="w-full mt-4">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="border-2 border-gray-300 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {newFilteredWorkPermitApplications.map((application, index) => (
              <tr key={index} className="border-2 border-gray-300">
                <td className="border-2 border-gray-300 p-2">
                  {application.identifier}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.name}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.id}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.status}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.createdAt}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.approvedAt}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {computeDaysPast(application.createdAt) - 1}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  <button
                    className="bg-blue-900 text-white px-2 py-1 rounded-lg"
                    onClick={() => handleViewDocuments(application)}
                  >
                    View documents
                  </button>
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.description}
                </td>
                <td className="border-2 border-gray-300 p-2">
                  {application.remarks}
                </td>
                <td className="border-2 border-gray-300 p-2 space-x-2">
                  {application.status === "Pending" && (
                    <>
                      <button
                        className="bg-green-500 text-white px-2 py-1 rounded-lg"
                        onClick={() => handleApproval(application)}
                      >
                        Approve
                      </button>
                      <button
                        className="bg-red-500 text-white px-2 py-1 rounded-lg"
                        onClick={() => handleDecline(application)}
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

      {viewMode && (
        <PageModal>
          <WorkPermitDetails
            application={selectedApplication}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}
    </div>
  );
}

export default WorkPermits;

import React, { useState } from "react";
import InputBox from "../Components/InputBox";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  addNewDoc,
  setDocData,
  storage,
} from "../../../utils/firebase/Firebase.utils";
import { current } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { doc } from "firebase/firestore";
import toast from "react-hot-toast";
import SelectInput from "../Components/SelectInput";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useRef } from "react";

export default function AnnouncementForm({ data, setAddMode }) {
  const uploadingRef = useRef(null);
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = React.useState(data || {});
  const [file, setFile] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `announcements/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, poster: downloadURL }));
        });
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (data) {
      await setDocData("announcements", data.id, formData);
      toast.success("Announcement updated successfully");
      setIsProcessing(false);
      setAddMode(false);

      return;
    }

    const docObject = {
      ...formData,
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      user: currentUser.uid,
      status: "unpublished",
    };

    await addNewDoc("announcements", docObject);
    toast.success("Announcement added successfully");
    setAddMode(false);
  };

  const typeOptions = [
    { value: "announcements", label: "Announcements" },
    { value: "newsletters", label: "Newsletters" },
  ];

  return (
    <div className="page relative pt-8 text-left">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="header">Announcement Form</div>

      <div className="mt-4">
        <div className="header">Create a new announcement</div>

        <form onSubmit={handleSave} className="mt-4">
          <div className="w-96">
            <div>
              <SelectInput
                name="type"
                label="Type"
                options={typeOptions}
                required
                onChange={(selected) =>
                  setFormData({ ...formData, type: selected.value })
                }
                value={typeOptions.find((item) => item.value === formData.type)}
              />
            </div>

            <InputBox
              name="title"
              label="Title"
              type="text"
              placeholder="Enter Title"
              required
              onChange={handleChange}
              value={formData.title}
            />

            <InputBox
              name="description"
              label="Description"
              type="text"
              placeholder="Enter Description"
              required
              onChange={handleChange}
              value={formData.description}
            />

            <div className="mt-4 w-full text-left">
              <label className=" text-gray-600 text-left uppercase font-bold text-xs">
                Details
              </label>
              <textarea
                className="border p-2 rounded-lg border-gray-600 w-full"
                name="Details"
                rows={6}
                placeholder="Enter Details of Announcement"
                onChange={handleChange}
                required
                value={formData.Details}
              ></textarea>
            </div>

            <div>
              <div
                className="p-2 border border-gray-200 rounded-lg mt-4 h-60 w-96 border-dashed flex flex-col items-center justify-center cursor-pointer hover:bg-gray-200"
                onClick={() => uploadingRef.current.click()}
                style={
                  formData.poster
                    ? {
                        backgroundImage: `url(${formData.poster})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }
                    : {}
                }
              >
                {formData.poster ? null : (
                  <div className="flex flex-col items-center justify-center">
                    <IoCloudUploadOutline className="text-6xl text-gray-400" />
                    <div className="font-bold capitalize text-gray-600">
                      Upload Photo Here
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="text-left hidden">
              <div>Upload Poster Photo</div>
              <input type="file" ref={uploadingRef} onChange={handleUpload} />
            </div>
          </div>

          {!data.poster && <div>{progress} % Uploaded</div>}

          <div className="mt-4">
            <button
              className="blue-button"
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

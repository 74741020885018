import React from "react";

export default function GuestPassCard({ data }) {
  return (
    <div className="bg-white flex flex-col lg:flex-row items-start lg:items-center rounded-md shadow-sm p-2 cursor-pointer hover:bg-gray-200">
      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
          Lot Number
        </div>
        <div className="font-bold px-1 text-blue-900">
          {data.linkedIdentifiers[0] || "Not Set"}
        </div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-24 px-1 ">
          Number of guests
        </div>
        <div className="font-bold px-1 text-blue-900">{data.guests.length}</div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
          Date of Visit
        </div>
        <div className="font-bold px-1 text-blue-900 capitalize">
          {new Date(data.date).toDateString()}
        </div>
      </div>

      <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
        <div className="rounded-full text-[8px] bg-gray-100 w-24 px-1 ">
          Date of Request
        </div>
        <div className="font-bold px-1 text-blue-900 capitalize">
          {new Date(data.requestDate).toDateString()}
        </div>
      </div>

      <div className={`capitalize ${data.status.toLowerCase()}`}>
        {data.status}
      </div>
    </div>
  );
}

import React from "react";
import { Link, NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import WorkIcon from "@mui/icons-material/Work";
import InventoryIcon from "@mui/icons-material/Inventory";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CampaignIcon from "@mui/icons-material/Campaign";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { MdAnalytics } from "react-icons/md";
import { TiContacts } from "react-icons/ti";
import { RxHamburgerMenu } from "react-icons/rx";
import { motion } from "framer-motion";

import ApartmentIcon from "@mui/icons-material/Apartment";
import { FaCar } from "react-icons/fa";

function Navbar() {
  const [activeLink, setActiveLink] = React.useState("overview");
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);

  const navbaritems = [
    {
      name: "Overview",
      icon: <DashboardIcon />,
      link: "/overview",
    },
    {
      name: "Resident Management",
      icon: <ReceiptLongIcon />,
      link: "/resident-management?link=Owners",
    },
    {
      name: "Property Management",
      icon: <WorkIcon />,
      link: "/property-management?type=Work+Permits",
    },
    {
      name: "Security",
      icon: <LocalPoliceIcon />,
      link: "/security",
    },
    {
      name: "Facilities",
      icon: <ApartmentIcon />,
      link: "/facilities",
    },

    {
      name: "Analytics",
      icon: <MdAnalytics />,
      link: "/analytics",
    },

    {
      name: "Announcements",
      icon: <CampaignIcon />,
      link: "/announcements",
    },
    {
      name: "Billing",
      icon: <SummarizeIcon />,
      link: "/billing",
    },
    {
      name: "Suppliers",
      icon: <TiContacts />,
      link: "/services",
    },
    {
      name: "HOA",
      icon: <ContactMailIcon />,
      link: "/contacts",
    },
  ];

  const moreitems = [
    {
      name: "Car Stickers",
      icon: <FaCar />,
      link: "/car-stickers",
    },
  ];

  const handleShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className="px-2 relative navbar z-10 bg-blue-900 w-screen h-[40px] text-white flex items-center justify-center poppins-regular shadow-gray-600 shadow-md lg:sticky top-0 max-sm:p-2">
      <div className="lg:hidden text-left w-full flex items-center">
        <button onClick={handleShowMobileMenu}>
          <RxHamburgerMenu className="text-2xl" />
        </button>
      </div>
      <div>
        <ul className="flex items-center gap-2">
          {navbaritems.map((item, index) => (
            <div
              key={index}
              className={`hidden lg:flex ${
                activeLink === item.name.toLowerCase()
                  ? "bg-green-500 text-gray-800"
                  : "hover:bg-green-500 hover:text-gray-800"
              } cursor-pointer hover:bg-green-500 hover:text-gray-800 p-1 rounded-xl transition-all duration-300 ease-in-out text-xs`}
            >
              <NavLink
                to={item.link}
                onClick={() => setActiveLink(item.name.toLowerCase())}
              >
                <div className="flex items-center gap-1">
                  <div className="text-sm"> {item.icon}</div>
                  <span className="">{item.name}</span>
                </div>
              </NavLink>
            </div>
          ))}

          <div className="capitalize underline text-xs cursor-pointer hover:text-gray-300">
            <div onClick={() => setShowMore(!showMore)}>more</div>

            {
              <div
                className={`${
                  showMore ? "block" : "hidden"
                } absolute bg-blue-900 text-white p-2 rounded-md`}
              >
                {moreitems.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.link}
                    onClick={() => {
                      setActiveLink(item.name.toLowerCase());
                      setShowMore(false);
                    }}
                  >
                    <div className="flex items-center gap-1">
                      <div className="text-sm"> {item.icon}</div>
                      <span className="">{item.name}</span>
                    </div>
                  </NavLink>
                ))}
              </div>
            }
          </div>
        </ul>
      </div>

      {showMobileMenu && (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%" }}
          transition={{ duration: 0.3, type: "tween" }}
          className="lg:hidden absolute top-10 left-0 bg-blue-900 text-white h-screen w-screen p-8"
        >
          {
            <ul className="flex flex-col items-start gap-2">
              {navbaritems.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    activeLink === item.name.toLowerCase()
                      ? "bg-green-500 text-gray-800"
                      : "hover:bg-green-500 hover:text-gray-800"
                  } cursor-pointer hover:bg-green-500 hover:text-gray-800 p-2 rounded-md transition-all duration-300 ease-in-out text-xs py-1`}
                >
                  <NavLink
                    to={item.link}
                    onClick={() => {
                      setActiveLink(item.name.toLowerCase());
                      setShowMobileMenu(false);
                    }}
                  >
                    <div className="flex items-center gap-1">
                      <div className="text-lg"> {item.icon}</div>
                      <span>{item.name}</span>
                    </div>
                  </NavLink>
                </div>
              ))}
            </ul>
          }
        </motion.div>
      )}
    </div>
  );
}

export default Navbar;

import React from "react";

export default function BillingPageDetails({ data, setViewMode }) {

  return (
    <div className="page relative text-gray-600 text-xs text-left">
      <button className="close-button " onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="header">Billing Page Details</div>

      <div>
        <div>{data.soaNo}</div>
        <div>{data.status}</div>
        <img src={data.depositSlip} alt="" height={200} width={200} />
      </div>
    </div>
  );
}

import React from "react";
import UserScreenWindow from "./UserScreenWindow";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function RequestDetails() {
  const { id } = useParams();
  const [request, setRequest] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [comments, setComments] = React.useState([]);
  const [newComment, setNewComment] = React.useState("");

  const currentUser = useSelector((state) => state.userObject);

  React.useEffect(() => {
    const fetchRequest = async () => {
      const data = await getDocDataSnap("workPermits", id, (data) => {
        setComments(data.comments || []);
        setRequest(data);
        setIsLoading(false);
      });
    };
    fetchRequest();
  }, []);

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <UserScreenWindow label="Request Details">
        <div>Loading...</div>
      </UserScreenWindow>
    );
  }

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleNewComment = async () => {
    //check if the comment is not empty
    if (newComment.trim() === "") {
      toast.error("Comment cannot be empty");
      return;
    }

    const newComments = [
      ...comments,
      {
        name: currentUser.displayName,
        createdAt: new Date().toLocaleString(),
        message: newComment,
      },
    ];
    setComments(newComments);
    setNewComment("");
    await setDocData("workPermits", id, {
      comments: newComments,
    });
  };

  const handleEditRequest = () => {
    navigate(`/work-permits?continue=${id}`);
  };

  //create a files array from the files object keys as label and values as url
  // const filesArray = Object.keys(request.files).map((key) => {
  //   return { name: key, url: request.files[key] };
  // });

  const filesArray =
    request.files !== undefined
      ? Object.keys(request.files).map((key) => {
          return { name: key, url: request.files[key] };
        })
      : [];

  return (
    <UserScreenWindow label="Request Details">
      <div className="bg-white rounded-xl p-2 mt-4 text-gray-600 text-xs">
        {request.status === "Returned" && (
          <div className="text-right">
            <button onClick={handleEditRequest} className="blue-button">
              Edit this request
            </button>
          </div>
        )}
        <div className="header">Request Details</div>
        <div className="p-2 text-xs text-gray-600 flex items-center gap-4 justify-between">
          <div className="font-bold">Request ID Number</div>
          <div>{id}</div>
        </div>
        <div className="p-2 ">
          <div className="">
            <div className="flex items-center justify-between">
              <div>Work Permit Type</div>
              <div className="capitalize">{request.workPermitType}</div>
            </div>

            <div className="flex items-center justify-between">
              <div>Lot Number</div>
              <div className="capitalize">{request.identifier}</div>
            </div>

            <div className="flex items-center justify-between">
              <div>Duration</div>
              <div className="capitalize">{request.duration}</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-xs font-bold text-gray-600">
              Work Description
            </div>
            <div>{request.description}</div>
          </div>
          <div className="mt-4">
            <div className="text-xs font-bold text-gray-600">
              Names of Workers
            </div>
            <div>{request.workers}</div>
          </div>
          <div className="mt-4">
            <div className="text-xs font-bold text-gray-600">
              Vehicle Information
            </div>
            <div>{request.vehicle}</div>
          </div>
        </div>
        <div className="header">Files Uploaded</div>
        <div className="flex flex-col gap-2 ">
          {filesArray.map((file, index) => (
            <div className=" flex items-center justify-between" key={index}>
              <div>{file.name}</div>
              <a
                href={file.url}
                target="_blank"
                key={index}
                className="px-2 py-1 bg-green-400 rounded-lg"
              >
                View File
              </a>
            </div>
          ))}
          {filesArray.length === 0 && <div>No files uploaded</div>}
        </div>

        <div className="header mt-4">Status</div>
        <div className={request.status.toLowerCase()}>{request.status}</div>

        <div className="header mt-4">Comments</div>
        {comments.map((comment, index) => (
          <div
            key={index}
            className="bg-gray-100 p-2 rounded-lg shadow-sm mt-2"
          >
            <div>{comment.name}</div>
            <div>{comment.message}</div>
            <div>{comment.createdAt}</div>
          </div>
        ))}

        {request.status !== "Approved" && (
          <div className="mt-4">
            <textarea
              className="w-full h-20 p-2 rounded-lg border"
              placeholder="Enter your reply here"
              onChange={handleChange}
              value={newComment}
            />
            <button
              className="bg-blue-900 text-white px-2 py-1 rounded-lg mt-4 float-right "
              onClick={handleNewComment}
            >
              Reply
            </button>
          </div>
        )}
      </div>
    </UserScreenWindow>
  );
}

export default RequestDetails;

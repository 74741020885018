import React from "react";

export default function Violations() {
  return (
    <div>
      <div className="header">Violation History</div>

      <button className="blue-button">Log a violation</button>
    </div>
  );
}

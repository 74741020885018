import React, { useEffect } from "react";
import UserBar from "./UserBar";
import SearchIcon from "@mui/icons-material/Search";
import { getDocDataSnap } from "../../utils/firebase/Firebase.utils";

function Header() {
  const id = "7Dw6sBMQrHLSiHoirHdN";
  const [company, setCompany] = React.useState({});

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await getDocDataSnap("company", id, (data) => {
        setCompany(data);
      });
    };
    fetchCompanyData();
  }, []);

  return (
    <div
      className="bg-gray-100 lg:h-[100px] w-screen
    flex items-center justify-around flex-wrap py-2 lg:p-0
    "
    >
      <div className="flex items-center gap-4">
        <img
          className="h-[80px] w-[100px] object-contain"
          src={
            company.companyLogo ||
            "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
          }
          alt=""
        />
        {company.companyLogo && (
          <div className="hidden lg:flex">
            <div className="text-xs text-gray-600">powered by</div>
            <div>
              <img
                className="h-[50px] w-[150px] object-contain"
                src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="relative hidden lg:flex items-center">
        <input
          type="text"
          placeholder="Search for items"
          className="bg-gray-200 rounded-lg p-2 w-[450px]"
        />
      </div> */}
      <div>
        <UserBar />
      </div>
    </div>
  );
}

export default Header;

import React, { useEffect } from "react";
import JobOrders from "./JobOrders";
import WorkPermits from "./WorkPermits";
import WaterMeterReading from "./WaterMeterReading";
import { useSearchParams } from "react-router-dom";
import Maintenance from "./Maintenance";
import MaintenanceNewPage from "./Maintenance/MaintenanceNewPage";
import Inventoryv2 from "./Inventory/Inventoryv2";

function PropertyManagement() {
  const [activeLink, setActiveLink] = React.useState("Job Orders");
  const [searchParams, setSearchParams] = useSearchParams();

  const searchType = searchParams.get("type");

  const links = [
    "Work Permits",
    "Concerns",
    "Maintenance",
    "Inventory",
    "Water Meter Reading",
  ];

  useEffect(() => {
    if (searchType) {
      setActiveLink(searchType);
    }
  }, []);

  useEffect(() => {
    setSearchParams({ type: activeLink });
  }, [activeLink]);

  return (
    <div className="p-2 text-xs">
      <div className="links-container">
        {links.map((link, index) => (
          <div
            key={index}
            className={`${
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            } cursor-pointer`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>
      <div className="mt-8 text-gray-600">
        {
          {
            Concerns: <JobOrders />,
            "Work Permits": <WorkPermits />,
            Inventory: <Inventoryv2 />,
            "Water Meter Reading": <WaterMeterReading />,
            Maintenance: <MaintenanceNewPage />,
          }[activeLink]
        }
      </div>
    </div>
  );
}

export default PropertyManagement;

import React from "react";

export default function CarStickerApplicationDetails({ setViewMode, data }) {
  return (
    <div className="page relative text-xs text-gray-600">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div className="header">Car Sticker Application</div>
      <div>{data.id}</div>

      <div className="w-96 mt-4 divide-y divide-gray-300">
        <div className="flex items-center justify-between py-2">
          <div>Requested by</div>
          <div>{data.requestedBy}</div>
        </div>
        <div className="flex items-center justify-between py-2">
          <div>Owner</div>
          <div>{data.carOwner}</div>
        </div>

        <div className="flex items-center justify-between py-2">
          <div>Car Model</div>
          <div>{data.carModel}</div>
        </div>

        <div className="flex items-center justify-between py-2">
          <div>Color</div>
          <div>{data.carColor}</div>
        </div>

        <div className="flex items-center justify-between py-2">
          <div>Owner</div>
          <div>{data.carOwner}</div>
        </div>

        <div className="flex items-center justify-between py-2">
          <div>Plate</div>
          <div>{data.plateNumber}</div>
        </div>

        <div className="flex items-center justify-between py-2">
          <div>Status</div>
          <div>{data.status}</div>
        </div>
      </div>
    </div>
  );
}

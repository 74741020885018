import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllDocs,
  getAllDocsSnap,
} from "../../../utils/firebase/Firebase.utils";
import { Link } from "react-router-dom";
import NotificationCard from "../../User/NotificationCard";
import BentoTable from "../../../components/BentoTable";

export default function NotificationWeb() {
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [numberOfItemPerPage, setNumberOfItemPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getAllDocsSnap("notifications", (data) => {
        //filter active requests by email
        const userNotifications = data.filter(
          (notification) => notification.user === "admin@tali.com"
        );

        //sort notifications by date
        userNotifications.sort((a, b) => b.dateTime - a.dateTime);

        setNotifications(userNotifications);
        setIsLoading(false);
      });
    };
    fetchNotifications();
  }, []);

  if (isLoading) {
    return (
      <div label="Notifications">
        <div>Loading...</div>
      </div>
    );
  }

  const slicedItems = notifications.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div label="Notifications ">
      {notifications.length === 0 && (
        <div className="text-center text-gray-500 mt-4">
          No notifications available
        </div>
      )}

      <BentoTable
        label="Notifications"
        data={notifications}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="border-t border-gray-200">
          {slicedItems.map((notification, index) => (
            <Link key={index} to={`/notif-details/${notification.id}`}>
              <NotificationCard notification={notification} />
            </Link>
          ))}
        </div>
      </BentoTable>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  getAllDocsSnap,
  addNewDoc,
  storage,
  deleteDocById,
  setDocData,
} from "../../utils/firebase/Firebase.utils";
import Modal from "./Components/Modal";
import InputBox from "./Components/InputBox";
import Label from "./Components/Label";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import PageModal from "./Components/PageModal";
import SupplierInformationPage from "./Suppliers/SupplierInformationPage";
import { useSelector } from "react-redux";
import SelectInput from "./Components/SelectInput";
import NoLogo from "../assets/nologo.gif";
import { v4 } from "uuid";

function Services() {
  const currentUser = useSelector((state) => state.userObject);
  const [activeService, setActiveService] = React.useState("");

  const [suppliers, setSuppliers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [inProcess, setInProcess] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [uploadingInProgress, setUploadingInProgress] = useState(false);
  const [supplierEdit, setSupplierEdit] = useState(false);

  const categoryOptions = [
    { value: "delivery", label: "Delivery" },
    { value: "services", label: "Services / Installation" },
    { value: "grocery", label: "Grocery and Pharmacy" },
    { value: "restaurants", label: "Restaurants" },
    { value: "medical", label: "Hospital and Medical Services" },
  ];

  useEffect(() => {
    const fetchSuppliers = async () => {
      await getAllDocsSnap("suppliers", (data) => {
        //sort suppliers by name
        data.sort((a, b) => a.name.localeCompare(b.name));

        setSuppliers(data);
        setLoading(false);
      });
    };
    fetchSuppliers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // const services = ["laundry", "cleaning", "water"];
  const services = [];

  const activeServiceStyle = "bg-green-500  ";

  const handleAddMode = () => {
    setAddMode(!addMode);
    setProgress(0);
    setFormData({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    if (supplierEdit) {
      const docObject = {
        ...formData,
        updatedBy: currentUser.email,
        updatedAt: new Date().toLocaleString(),
      };
      await setDocData("suppliers", formData.id, docObject);
      toast.success("Supplier updated successfully");
      setSupplierEdit(false);
      setInProcess(false);
      setAddMode(false);
      return;
    }

    await addNewDoc("suppliers", formData);
    toast.success("Supplier added successfully");
    setInProcess(false);
    setAddMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `suppliers/${file.name} + ${v4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setUploadingInProgress(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prev) => ({ ...prev, logo: downloadURL }));
        });

        setUploadingInProgress(false);
      }
    );
  };

  const handleServices = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, services: [value] }));
  };

  const handleViewMode = (selected) => {
    if (editMode) {
      return;
    }

    setSelectedSupplier(selected);
    setViewMode(!viewMode);
  };

  const filteredItems = suppliers.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.services.some((service) =>
        service.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleDelete = async (id) => {
    if (currentUser.roles.includes("admin")) {
      if (window.confirm("Are you sure you want to delete this supplier?")) {
        await deleteDocById("suppliers", id);
        toast.success("Supplier deleted successfully");
      }
    } else {
      toast.error("You are not authorized for a delete function");
    }
  };

  const handleEditSupplier = (supplier) => {
    setSupplierEdit(true);
    setFormData(supplier);
    setAddMode(true);
  };

  return (
    <div className="text-blue-900 p-2">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg ">Accredited Suppliers</div>
        <div className="flex items-center justify-normal gap-4"></div>

        <div className="flex items-center gap-2">
          <button className="blue-button" onClick={handleAddMode}>
            Add
          </button>
          <button className="blue-button" onClick={handleEditMode}>
            {editMode ? "Done" : "Edit"}
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between border border-gray-300 px-4 py-2 text-xs">
        <div className="flex items-center justify-normal rounded-lg border border-gray-300 divide-x">
          {services.map((service, index) => (
            <div
              key={index}
              className={`p-2 cursor-pointer min-w-20 capitalize ${
                activeService === service ? activeServiceStyle : ""
              }`}
              onClick={() => setActiveService(service)}
            >
              {service}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="Search by Name, or Service"
          className="border border-gray-300 w-96 rounded-lg px-4 py-2"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div>Filter</div>
      </div>

      <div className="flex items-start lg:justify-center gap-4 mt-8 flex-wrap justify-center ">
        {filteredItems.map((supplier, index) => (
          <div
            key={index}
            className=" relative border border-gray-300 w-60 h-60 p-4 rounded-lg bg-white flex flex-col items-center justify-start text-sm text-gray-600 hover:border-blue-900 hover:border cursor-pointer"
            onClick={() => handleViewMode(supplier)}
          >
            {editMode && (
              <div className="absolute bottom-2 flex items-center gap-3">
                <button
                  className="green-button "
                  onClick={() => handleEditSupplier(supplier)}
                >
                  Edit
                </button>
                <button
                  className="red-button "
                  onClick={() => handleDelete(supplier.id)}
                >
                  Delete
                </button>
              </div>
            )}
            <div className="mb-2">
              <img
                src={supplier.logo || NoLogo}
                className="w-24 h-24 object-contain"
                alt=""
              />
            </div>
            <div className="header">{supplier.name}</div>
            <div className="font-bold">{supplier.phone}</div>
            <div>
              {supplier.services.map((service, index) => (
                <div key={index}>{service}</div>
              ))}
            </div>
            {/* <div>
              {supplier.address === "not indicated" ? "" : supplier.address}
            </div> */}
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <SupplierInformationPage
            data={selectedSupplier}
            setViewMode={setViewMode}
          />
        </PageModal>
      )}

      {addMode && (
        <div>
          <Modal>
            <form action="" onSubmit={handleSubmit} className="relative">
              <button
                type="button"
                className="close-button"
                onClick={handleAddMode}
              >
                Close
              </button>
              <div className="p-4">
                <div className="font-bold text-sm text-blue-900">
                  Add Supplier
                </div>
                <div className="mt-4">
                  <div className="flex items-center gap-4 flex-wrap">
                    <InputBox
                      label={"Name"}
                      type="text"
                      name="name"
                      id="name"
                      placeholder={"Supplier Name"}
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleChange}
                      required
                      value={formData.name}
                    />

                    <SelectInput
                      label={"Category"}
                      type="text"
                      name="category"
                      placeholder={"Category"}
                      id="name"
                      className="border border-gray-300 rounded-lg px-4 py-2 text-left w-72"
                      required
                      options={categoryOptions}
                      onChange={(e) => {
                        setFormData({ ...formData, category: e.value });
                      }}
                      value={categoryOptions.find(
                        (option) => option.value === formData.category
                      )}
                    />

                    <InputBox
                      label={"Type of Service"}
                      type="text"
                      name="services"
                      placeholder={"Type of Service"}
                      id="name"
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleServices}
                      required
                      value={formData.services}
                    />
                    <InputBox
                      label={"Email Address"}
                      type="email"
                      placeholder={"Email Address"}
                      name="email"
                      id="email"
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleChange}
                      value={formData.email}
                    />
                    <InputBox
                      label={"Phone"}
                      placeholder={"Phone Number"}
                      type="text"
                      name="phone"
                      id="phone"
                      className="border border-gray-300 rounded-lg px-4 py-2"
                      onChange={handleChange}
                      required
                      value={formData.phone}
                    />
                  </div>
                  <div className="mt-4">
                    <div>
                      <InputBox
                        label={"Address"}
                        placeholder={"Address"}
                        type="text"
                        name="address"
                        id="address"
                        className="border border-gray-300 rounded-lg px-4 py-2"
                        onChange={handleChange}
                        value={formData.address}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div>
                      <Label label={"Logo"}>
                        <input
                          type="file"
                          name="logo"
                          id="logo"
                          className="border border-gray-300 rounded-lg px-4 py-2"
                          onChange={handleUpload}
                        />
                        <div>{progress}%</div>
                      </Label>

                      <img
                        src={formData.logo || NoLogo}
                        alt=""
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="blue-button"
                      disabled={uploadingInProgress || inProcess}
                    >
                      {supplierEdit ? "Update" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Services;

import React, { useEffect, useState } from "react";
import AddContactForm from "./AddContactForm";
import Modal from "../Dashboard/Components/Modal";
import {
  deleteDocById,
  getAllDocsSnap,
} from "../../utils/firebase/Firebase.utils";
import toast from "react-hot-toast";
import BentoTable from "../../components/BentoTable";

export default function ContactsAdmin() {
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState({});
  const [activeLink, setActiveLink] = useState("HOA Directory");
  const [searchTerm, setSearchTerm] = useState("");

  const [numberOfItemPerPage, setNumberOfItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const links = ["HOA Directory", "Emergency Hotlines"];

  useEffect(() => {
    const fetchContacts = async () => {
      await getAllDocsSnap("contacts", (data) => {
        setContacts(data);
        setLoading(false);
      });
    };
    fetchContacts();
  }, []);

  if (loading) {
    return <div>Loading</div>;
  }

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const handleEdit = (contact) => {
    setEditMode(true);
    setSelectedContact(contact);
  };

  const handleDelete = async (contact) => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      await deleteDocById("contacts", contact.id);
      toast.success("Contact Deleted Successfully");
    }
  };

  const currentList = contacts.filter((contact) => {
    return contact.category === activeLink;
  });

  const filteredList = currentList.filter((contact) => {
    return (
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const slicedItems = filteredList.slice(
    (currentPage - 1) * numberOfItemPerPage,
    currentPage * numberOfItemPerPage
  );

  return (
    <div className="text-left space-y-4">
      <div className="header">Contacts</div>

      <div className="border rounded-lg border-gray-300 w-96 p-2 bg-white">
        <input
          className="w-full border-none outline-none"
          placeholder="Search name or title"
          type="search"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div>
        <button className="blue-button" onClick={handleAddMode}>
          Add New
        </button>
      </div>
      <div className="links-container">
        {links.map((link, index) => (
          <div
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      <BentoTable
        label={activeLink}
        data={filteredList}
        numberOfItemPerPage={numberOfItemPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div className="divide-y-2 divide-gray-200">
          {slicedItems.map((contact, index) => (
            <div
              className="card flex items-center justify-between flex-wrap text-xs"
              key={index}
            >
              <div
                key={index}
                className="flex items-center gap-4 w-full flex-wrap"
              >
                <div className="uppercase font-bold text-blue-900 w-72">
                  {contact.name}
                </div>
                <div className="w-60">{contact.title}</div>
                <div className="w-60">{contact.email}</div>
                <div className="w-60">{contact.contactNumber}</div>
                <div className="w-60">{contact.landline}</div>
              </div>
              <div className="flex items-center gap-4">
                <button
                  className="underline text-xs text-gray-400"
                  onClick={() => handleEdit(contact)}
                >
                  Edit
                </button>
                <button
                  className="underline text-xs text-gray-400"
                  onClick={() => handleDelete(contact)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </BentoTable>

      {addMode && (
        <Modal>
          <AddContactForm setAddMode={setAddMode} />
        </Modal>
      )}

      {editMode && (
        <Modal>
          <AddContactForm
            data={selectedContact}
            setEditMode={setEditMode}
            setAddMode={setAddMode}
            editMode={editMode}
          />
        </Modal>
      )}
    </div>
  );
}

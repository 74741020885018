import React from "react";

export default function BillingCard({ data }) {
  return (
    <div className="flex items-center justify-normal gap-2 p-2 text-xs text-left">
      <div className="w-32">{data.soaNo}</div>
      <div className="w-40">{data.unitNo}</div>
      <div className="w-48 font-bold capitalize">{data.name.toLowerCase()}</div>
      <div className="w-32">{data.billingDate}</div>
      <div className={`w-32 ${data.status}`}>{data.status}</div>
      <div className="w-24 text-right mr-12">
        {parseFloat(data.amount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
      <div className="w-32">{data.paymentDate}</div>
    </div>
  );
}

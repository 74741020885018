import React, { useState } from "react";
import InputBox from "../Components/InputBox";
import { setDocData } from "../../../utils/firebase/Firebase.utils";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { doc } from "firebase/firestore";
import toast from "react-hot-toast";

export default function AddResidentOccupantForm({ handleAddMode, occupants }) {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [assignedId, setAssignedId] = useState(v4());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const docObject = {
      ...formData,
      id: assignedId,
    };
    const newOccupants = [...occupants, docObject];
    await setDocData("residents", id, { occupantsName: newOccupants });
    toast.success("Occupant added successfully");
    handleAddMode();
    setIsProcessing(false);
  };

  return (
    <div className="small-form text-gray-600 text-left pt-8 relative">
      <div>
        <button className="close-button" onClick={handleAddMode}>
          Close
        </button>
      </div>

      <div className="header">Register an Occupant</div>

      <div>
        <form className="space-y-4" action="" onSubmit={handleSubmit}>
          <InputBox
            label="First Name"
            name="firstName"
            onChange={handleChange}
            placeholder={"First Name"}
            required
          />

          <InputBox
            label="Middle Name"
            name="middleName"
            onChange={handleChange}
            placeholder={"Middle Name"}
          />

          <InputBox
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            placeholder={"Last Name"}
            required
          />

          <InputBox
            label="Birthday"
            name="birthday"
            type="date"
            onChange={handleChange}
            placeholder={"Birthday"}
          />

          <InputBox
            label="Relationship to the Owner"
            name="relationship"
            type="text"
            onChange={handleChange}
            placeholder={"e.g. Owner, Father, Son, Daughter, Wife, Husband"}
          />

          <div>
            <button
              className="blue-button w-full"
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Add Occupant"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
